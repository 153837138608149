.search-date-wrap {
    display: flex;
    align-items: center; /* 아이템들을 수직 중앙 정렬 */
    justify-content: space-between; /* 아이템들 사이의 공간을 균등하게 분배 */
  }
  
  .search-date-wrap,
  .srch-date-input-wrap {
    display: flex;
    align-items: center; /* 날짜 입력란과 검색란 내의 아이템들을 수직 중앙 정렬 */
  }
  
  .search-date-wrap input[type="date"],
  .srch-date-input-wrap input {
    margin: 0 5px; /* 입력 필드와 기타 요소 사이의 간격 */
    font-family: 'Pretendard', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .srch-date-input-wrap input:focus-visible {
    outline: 0;
  }
  .srch-date-input-wrap input:focus {
    outline: 0;
  }
  
  .search-date-wrap select {
    width: 160px;
    height: 36px;
    margin-right: 8px;
    border: 1px solid #d1d1d1;
    border-radius: 7px;
  }
  .search-date-wrap select:focus-visible {
    outline: 0;
  }
  .search-date-wrap select:focus {
    outline: 0;
  }
  
  .search-date-wrap select {
    padding: 0 0.5rem;
  }
  .search-date-wrap input {
    padding: 0 0.5rem;
    margin: 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    width: 140px;
    height: 36px;
    border-radius: 7px;
  }